import './App.css';
import { BrowserRouter as Router, Route, Routes, Link, useParams, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Analytics } from "@vercel/analytics/react"
import { Helmet } from 'react-helmet-async';

function App() {
  const [poems, setPoems] = useState([]);

  useEffect(() => {
    const poemFiles = ['he,-abandoned-by-them']; // Add more filenames as needed
    const fetchPoems = async () => {
      const fetchedPoems = await Promise.all(
        poemFiles.map(async (filename, index) => {
          try {
            const response = await fetch(`/poems/${filename}.txt`);
            const content = await response.text();
            return {
              id: index + 1,
              title: filename.replace(/-/g, ' '),
              content: content,
              link: filename.replace(/,/g, '') // Remove commas from the link
            };
          } catch (error) {
            console.error('Error fetching the file:', error);
            return null;
          }
        })
      );
      setPoems(fetchedPoems.filter(poem => poem !== null));
    };
    fetchPoems();
  }, []);

  return (
    <Router>
      <Analytics />
      <AppContent poems={poems} />
    </Router>
  );
}

function AppContent({ poems }) {
  const location = useLocation();

  return (
    <div>
      {/* <head>
        <meta name="og:title" content="maggie finch" />
        <meta name="og:description" content="sometimes, i write poetry." />
        <meta name="og:image" content={`${window.location.origin}/white_square_192.png`} />
        <meta name="og:url" content={window.location.href} />
      </head> */}
      <div className="App max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 flex flex-col md:flex-row" style={{ fontFamily: 'Times New Roman, serif' }}>
        <div className="md:w-1/3 p-4 md:fixed md:h-full overflow-y-auto flex flex-col" style={{ marginBottom: '4rem' }}>
          <header className="App-header mb-4">
            <h1 className='text-lg font-bold'>
              <Link to="/" style={{ color: 'black', textDecoration: 'none' }}>
                maggie finch
              </Link>
            </h1>
          </header>
          <div className="poems flex-grow">
            {poems.map((poem) => (
              <article key={poem.id}>
                <h2>
                  <Link 
                    to={`/${poem.link}`} 
                    style={{ 
                      color: location.pathname === `/${poem.link}` ? 'purple' : 'blue', 
                      textDecoration: 'underline' 
                    }}
                  >
                    {poem.title}
                  </Link>
                </h2>
              </article>
            ))}
          </div>
        </div>
        <div className="md:w-2/3 p-4 md:ml-auto flex flex-col">
          <Routes>
            <Route path="/" element={
              <div>
                <Helmet>
                  <title>maggie finch</title>
                  <meta name="description" content="i write poetry sometimes." />
                  <meta property="og:title" content="maggie finch" />
                  <meta property="og:description" content="i write poetry sometimes." />
                </Helmet>
                i write poetry sometimes.
              </div>
            } />
            <Route path="/:title" element={<PoemPage poems={poems} />} />
          </Routes>
          <footer className={`mt-auto md:hidden`}>
            <p className="text-sm pt-8" style={{ marginBottom: '4rem', marginTop: '4rem' }}>maggiefinch@proton.me</p>
          </footer>
        </div>
        <footer className={`hidden md:block md:w-1/3 md:fixed md:bottom-0 p-4`}>
          <p className="text-sm pt-8" style={{ marginBottom: '4rem', marginTop: '4rem' }}>maggiefinch@proton.me</p>
          </footer>
        </div>
    </div>
  );
}

function PoemPage({ poems }) {
  const { title } = useParams();
  const poem = poems.find(p => p.link === title);

  if (!poem) {
    return <div>poem not found.</div>;
  }

  return (
    <div style={{ fontFamily: 'Times New Roman, serif' }}>
      <Helmet>
        <meta name="twitter:title" content={poem.title} />
        <meta name="twitter:description" content="maggie finch" />
        <meta property="og:title" content={poem.title} />
        <meta property="og:description" content="maggie finch" />
      </Helmet>
      <h1 className='text-lg font-bold'>{poem.title}</h1>
      <h1 className='text-lg font-bold'>---</h1>
      <div className='flex flex-col gap-6 pb-48'>
        {poem.content.split('\n\n').map((paragraph, index) => (
          <div key={index} className='flex flex-col'>
            {paragraph.split('\n').map((line, lineIndex) => (
              <p key={lineIndex}>{line}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
